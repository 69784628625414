import { designSystem } from '../designSystem';
import { Color } from '../../TSTypes/button';

const DangerColorState = {
  default: {
    color: designSystem.textColors.default,
    backgroundColor: 'transparent',
    border: 'none',
    textDecoration: 'none',
  },
  hover: {
    color: designSystem.textColors.default,
    backgroundColor: 'transparent',
    border: 'none',
    textDecoration: 'none',
  },
  focus: {
    color: designSystem.textColors.default,
    backgroundColor: 'transparent',
    border: 'none',
    textDecoration: 'none',
  },
  active: {
    color: designSystem.textColors.default,
    backgroundColor: 'transparent',
    border: 'none',
    textDecoration: 'none',
  },
  disabled: {
    color: designSystem.textColors.disabled,
    backgroundColor: 'transparent',
    border: 'none',
    textDecoration: 'none',
  },
};

const DangerSecondaryColorState = {
  default: {
    color: designSystem.textColors.default,
    backgroundColor: 'transparent',
    border: 'none',
    textDecoration: 'underline',
  },
  hover: {
    color: designSystem.textColors.default,
    backgroundColor: 'transparent',
    border: 'none',
    textDecoration: 'underline',
  },
  focus: {
    color: designSystem.textColors.default,
    backgroundColor: 'transparent',
    border: 'none',
    textDecoration: 'underline',
  },
  active: {
    color: designSystem.textColors.default,
    backgroundColor: 'transparent',
    border: 'none',
    textDecoration: 'underline',
  },
  disabled: {
    color: designSystem.textColors.default,
    backgroundColor: 'transparent',
    border: 'none',
    textDecoration: 'underline',
  },
};

const DangerColor: Color = {
  outlined: {
    true: {
      state: DangerSecondaryColorState,
    },
    false: {
      state: DangerColorState,
    },
  },
};

export default DangerColor;
