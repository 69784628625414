export const BILLING_ITEM_CODES = {
  SYSTEM_ACCESS: 'SYSTEM_ACCESS',
  SUBMISSION: 'SUBMISSION',
  PHOTO_UPLOAD: 'PHOTO_UPLOAD',
  AUDIO_UPLOAD: 'AUDIO_UPLOAD',
  VIDEO_UPLOAD: 'VIDEO_UPLOAD',
};

export const BILLING_CREDITS_MODEL = {
  UNLIMITED: -1,
  MUST_PURCHASE: 0,
};

export const BILLING_BANNER_MESSAGE_KIND = {
  CREATE_PROFILE: 'CREATE_PROFILE',
} as const;
