/**
 * Helper to get the coords of the passed element from the body
 * @param  {HtmlElement} element - element to get the position from the body
 * @returns {{top: number}} {top} - returns the top position of the element from the body
 */
function getElementCoordsFromBody(element): { top: number } {
  const { top } = element.getBoundingClientRect();

  const scrollTop = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;
  const clientTop = document.documentElement.clientTop || document.body.clientTop || 0;
  const elementTop = (top as number) + scrollTop - clientTop;

  return { top: Math.round(elementTop) };
}

export default getElementCoordsFromBody;
