import getElementCoordsFromBody from './getElementCoordsFromBody';

/**
 * Helper that animate the scroll unto the passed element
 * @param  {HtmlElement} element - element to scroll to
 * @param  {object} options - options for the scroll animation
 * @param  {number} options.duration - duration/time of the animation
 * @param  {number} options.offset - hardcoded pixels to move the scroll
 * @returns Promise - returns a "Node.js like result" where first element is error and second is the successful state,
 * it returns a Promise because in some times we are going to need than the animation finish to do some new things after that.
 */
const scrollToYAnimation = (element: HTMLElement, { duration = 100, offset = 0 }): Promise<unknown> => {
  if (!element) {
    return Promise.resolve([null, 'failure']);
  }

  const initialTime = performance.now();
  const currentScrollTopPosition = window.scrollY || document.documentElement.scrollTop;
  const { top: elementTop } = getElementCoordsFromBody(element);
  const pixelsTomove = elementTop - currentScrollTopPosition + offset;

  return new Promise((resolve) => {
    requestAnimationFrame(function scrollCb(timestamp) {
      const timePassed = timestamp - initialTime;
      const timeFraction = (timestamp - initialTime) / duration;
      const yScrollPosition = currentScrollTopPosition + pixelsTomove * timeFraction;

      window.scrollTo(0, yScrollPosition);

      if (timePassed - duration < 0) {
        return requestAnimationFrame(scrollCb);
      }

      return resolve([null, 'success']);
    });
  });
};

export default scrollToYAnimation;
