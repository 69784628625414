import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import loadable from '@loadable/component';
import { DesignSystemIconColor } from 'components/themes';

export type IconSize = 'xs' | 'ssm' | 'sm' | 'md' | 'lg' | 'xl' | 'spicerack';

export const iconSizes: Record<IconSize, string> = {
  xs: '0.5rem',
  ssm: '0.75rem',
  sm: '1rem',
  md: '1.5rem',
  lg: '2rem',
  xl: '2.5rem',
  spicerack: '1.25rem',
};

export type IconProps = {
  id?: string;
  name: string;
  alt?: string;
  inheritColor?: boolean;
  themeColor?: DesignSystemIconColor;
  size?: IconSize;
  color?: ((props: any) => any) | string;
  /** height in rem */
  height?: number | string;
  width?: number | string;
  className?: string;
  onClick?: (e?: any) => void;
};

type ErrorBoundraryState = { hasError: boolean };

const ColouredIcon = styled(({ component, ...props }) => React.cloneElement(component, props))`
  height: ${({ $height }) => ($height ? `${$height}rem` : undefined)};
  width: ${({ $width }) => ($width ? `${$width}rem` : undefined)};

  ${(iconProps) => {
    const size = iconProps.$size ? iconSizes[iconProps.$size] : undefined;
    const fill = iconProps.$themeColor
      ? iconProps.theme.designSystem.iconColors[iconProps.$themeColor]
      : iconProps.color;
    return css`
      && {
        height: ${size};
        width: ${size};
      }
      &&,
      g,
      mask,
      use {
        fill: ${fill};
      }
    `;
  }};

  ${(iconProps) =>
    iconProps.$inheritColor &&
    css`
      color: inherit;

      mask: {
        fill: currentColor;
      }

      g: {
        fill: currentColor;
      }

      d {
        fill: currentColor;
      }

      path: {
        fill: currentColor !important;
      }

      u {
        fill: currentColor;
      }

      use {
        fill: currentColor;
      }
    `};

  path[d='M0 0h24v24H0z'] {
    display: none;
  }
`;

class ErrorBoundary extends React.Component<any, any> {
  static getDerivedStateFromError(): ErrorBoundraryState {
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static componentDidCatch(error, errorInfo): void {
    console.error(error, errorInfo);
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      return (
        <svg
          stroke="currentColor"
          fill="currentColor"
          viewBox="0 0 24 24"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
          aria-labelledby="icon-unable-to-load"
        >
          <title id="icon-unable-to-load">Unable to load icon</title>
          <path fill="none" d="M0 0h24v24H0V0z" />
          <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5v-4.58l.99.99 4-4 4 4 4-3.99L19 12.43V19zm0-9.41l-1.01-1.01-4 4.01-4-4-4 4-.99-1V5h14v4.59z" />
        </svg>
      );
    }

    return this.props.children;
  }
}

const Icon: FC<IconProps> = ({ name, color, inheritColor, themeColor, size, height, width, ...props }) => {
  const preparedName = name?.toLowerCase()?.replace(/(?!^)_/g, '-');
  const IconToRender = loadable((prop) => import(/* webpackChunkName: "icon-" */ `./assets/${(prop as any).name}.svg`));

  return (
    <ColouredIcon
      {...props}
      $inheritColor={inheritColor}
      color={color}
      $themeColor={themeColor}
      $size={size}
      $height={height}
      $width={width}
      component={<IconToRender name={preparedName} {...props} />}
      data-level={themeColor}
    />
  );
};

const IconWithErrorBoundary: FC<IconProps> = (props) => {
  return (
    <ErrorBoundary>
      <Icon {...props} />
    </ErrorBoundary>
  );
};

export default IconWithErrorBoundary;
