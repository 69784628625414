import React from 'react';
import Alert from '@minecraft.alert';
import styled, { useTheme } from 'styled-components';
import Icon from '@minecraft.icon';
import { media, Theme } from '@minecraft.themes';
import { getEnvironment } from '@minecraft.environment';
import { FEATURE_FLAGS, useFeatureExperiment } from '@blocs.features-experiments';
import { useTranslation } from '@blocs.i18n';

const StyledAlert = styled(Alert)`
  display: grid;
  grid-template-columns: auto 1fr;
  margin: 0;
  background: ${({ theme }) => theme.designSystem.bgColors.impactedServiceAlert};
  padding: 1rem;

  p {
    display: inline-block;
    padding-left: 0.5rem;
    margin: 0;
    font-weight: initial;
  }

  button {
    display: none;
  }

  ${media.md`
    display: block;
  `}
`;

const ImpactedServiceAlert = () => {
  // Get the latest flag from flags service
  const impactedService = useFeatureExperiment(FEATURE_FLAGS.IMPACTED_SERVICE);
  const { i18n } = useTranslation();
  const theme = useTheme() as Theme;
  // Note that the audience value in the environment settings match exactly to the keys created in launch darkly
  const audience = getEnvironment().AUDIENCE;

  // Short circuit if there's no flag set or there's no message or if it's not enabled
  if (!impactedService?.[audience]?.[i18n?.language] || !impactedService[audience]?.enabled) {
    return null;
  }

  return (
    <StyledAlert type="notify" size="inPage" close="true" data-testid="impacted-service-wrapper">
      <Icon name="spicerack-conflict" color={theme.designSystem.textColors.warning} />
      <p>{impactedService[audience][i18n.language]}</p>
    </StyledAlert>
  );
};

export default ImpactedServiceAlert;
