interface DmaWithRegionCountryId {
  id: string | number;
  name: string;
  region?: {
    id: string | number;
    name: string;
    country?: {
      id: string | number;
      name: string;
      continent?: {
        id: string | number;
      };
    };
  };
}

interface CountryWithSupporingId {
  name?: string;
  id: string | number;
  supportingCountry?: {
    id: string | number;
  };
}

interface LocationOption {
  label: string;
  value: string;
}

interface LocationOptionWithOptions extends LocationOption {
  options: LocationOption[];
}

interface GroupedLocation extends LocationOption {
  options: LocationOptionWithOptions[];
}

export const sortLocationsByName = ({ label: firstName }, { label: secondName }) => {
  if (firstName === secondName) {
    return 0;
  }

  return firstName > secondName ? 1 : -1;
};

export const groupLocationsWithInitialData = <T extends DmaWithRegionCountryId>(
  locations: T[],
  initialGroupedLocations: GroupedLocation[]
) => {
  const locat = initialGroupedLocations.concat([]);
  const groupedLocations = locations.reduce((sum, current) => {
    const currentCity = {
      value: current?.id?.toString(),
      label: current?.name,
    };
    const currentRegion = {
      value: current?.region?.id?.toString(),
      label: current?.region?.name,
      options: [currentCity],
    };
    const currentCountry = {
      value: current?.region?.country?.id?.toString(),
      label: current?.region?.country?.name,
      continent: current?.region?.country?.continent?.id?.toString(),
      options: [currentRegion],
    };
    const isEmpty = !sum.length;
    const existingCountry = !isEmpty && sum.find(({ value }) => value === currentCountry.value);
    const existingRegion =
      existingCountry && existingCountry.options.find(({ value }) => value === currentRegion.value);

    if (isEmpty || !existingCountry) {
      sum.push(currentCountry);
    } else if (!existingRegion) {
      existingCountry?.options?.push(currentRegion);
    } else {
      existingRegion?.options?.push(currentCity);
    }

    return sum;
  }, locat);

  groupedLocations.forEach((country) =>
    country?.options?.sort(sortLocationsByName)?.forEach((region) => region.options.sort(sortLocationsByName))
  );

  return groupedLocations;
};

export const groupLocationsWithUserCountry = <T extends DmaWithRegionCountryId, C extends CountryWithSupporingId>(
  ungroupedLocations: T[],
  userCountry: C
) => {
  // users country of residence must be first in a list
  const initialGroupedLocations: GroupedLocation[] = [
    {
      label: userCountry?.name,
      value: userCountry?.id?.toString(),
      options: [],
    },
  ];

  return groupLocationsWithInitialData(ungroupedLocations, initialGroupedLocations);
};

export const groupWorkingLocations = <T extends DmaWithRegionCountryId, C extends CountryWithSupporingId>(
  locations: T[],
  showAllLocations: boolean,
  userCountry: C
) => {
  // previous logic was filtering by continent
  // now we restrict locations by user country
  const primaryLocations = showAllLocations
    ? locations
    : locations.filter(
        (dma) =>
          dma?.region?.country?.id === userCountry?.id ||
          dma?.region?.country?.id === userCountry?.supportingCountry?.id
      );

  const primaryGroupedLocations = groupLocationsWithUserCountry(primaryLocations, userCountry);
  const secondaryGroupedLocations = groupLocationsWithUserCountry(locations, userCountry);

  return {
    primaryGroupedLocations,
    secondaryGroupedLocations,
  };
};
