import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { useZendesk } from '@blocs.zendesk';
import { BasicContainer, FlexRow } from './Containers';

const StyledFixedFooter = styled(BasicContainer)`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 4rem;
  z-index: 1001;
  box-shadow: 0.0145rem 0.029rem 0.174rem rgba(27, 40, 50, 0.01698), 0.0335rem 0.067rem 0.402rem rgba(27, 40, 50, 0.024),
    0.0625rem 0.125rem 0.75rem rgba(27, 40, 50, 0.03), 0.1125rem 0.225rem 1.35rem rgba(27, 40, 50, 0.036),
    0.2085rem 0.417rem 2.502rem rgba(27, 40, 50, 0.04302), 0.5rem 1rem 6rem rgba(27, 40, 50, 0.06),
    0 0 0 0.0625rem rgba(27, 40, 50, 0.015);
`;

const StyledFlexRow = styled(FlexRow)`
  max-width: 1440px;
  margin: auto;
`;

export type FixedFooterProps = {
  StartSlot?: React.ReactNode;
  EndSlot?: React.ReactNode;
};

export const FixedFooter: FC<FixedFooterProps> = ({ StartSlot, EndSlot }) => {
  const { hide: hideZendesk, show: showZendesk } = useZendesk();

  useEffect(() => {
    // When this component is rendered we hide zendesk
    hideZendesk();

    return () => {
      // When unmounted we re-show zendesk
      showZendesk();
    };
    // Adding all  dependencies in this effect causes zendesk to flicker
  }, []);

  return (
    <>
      <StyledFixedFooter
        bgColor="card"
        padding={3}
        customBorders={{ top: { borderColor: 'default', borderSize: 0.06 } }}
        data-testid="fixed-footer-container"
      >
        <StyledFlexRow alignItems="center" justifyContent="space-between">
          <div data-testid="start-slot">{StartSlot}</div>
          <div data-testid="end-slot">{EndSlot}</div>
        </StyledFlexRow>
      </StyledFixedFooter>
      <div style={{ height: '4rem' }} />
    </>
  );
};
