import { Colors } from '../TSTypes/colors';

const primaryColor = '#2C9EDE';
const ltGrey1 = '#D8D8D8';
const grey3 = '#868E96';
const dkBlue4 = '#162833';
const primaryWhite = '#FFFFFF';
const ltGrey2 = '#F8F8F8';
const dkBlue1 = '#193E5F';
const darkGreen = '#147600';
const purple3 = '#997fc0';
const transparent = 'transparent';
const ltPurpleGrey1 = '#EBEBEB';
const studioBkgd = '#2F2E2E';
const orange800 = '#EF9500';
const orange900 = '#D75000';
const brown100 = '#AF751A';
const brown300 = '#8E5213';

const colors: Colors = {
  primary: {
    primary: primaryColor,
    salmon: '#FF9989',
    darkGrey: '#3A3A3A',
    darkGrey1: '#15222A',
    darkGrey2: '#13191c',
    darkGrey3: '#737373',
    black: '#000000',
    grey1: '#696969',
    grey2: '#9C9C9C',
    grey3,
    grey4: '#14222A',
    ltGrey1,
    ltGrey2: '#ECECEC',
    white: primaryWhite,
    dkBlue1,
    lightSalmon: '#FFFCF9',
    studioBkgd,
  },
  accent: {
    ltGrey2,
    ltOrange: '#FFF7ED',
    red: '#D0021B',
    dkBlue2: '#155173',
    dkBlue3: '#183342',
    dkBlue4,
    orange: '#EF9500',
    darkOrange: orange900,
    green: '#7ED321',
    darkGreen,
    yellow: '#FDEE2E',
    greyBlue1: '#D2D9E5',
    greyBlue2: '#E0E7F2',
    greyBlue3: '#ebeef3',
    greyBlue4: '#eeeff3',
    darkGreyBlue: '#4b5156',
    ltGrey3: '#E8E8E8',
    ltGrey4: '#C7C7C7',
    ltGrey5: '#c3c3c3',
    ltGrey6: '#f7f7f7',
    ltGrey7: '#e7e7e7',
    ltGrey8: '#E0E7F2',
    ltGrey9: '#CCCCCC',
    ltBlue: '#f9fcff',
    yellow2: '#ffd100',
    blueActive: '#13618e',
    black70: '#4d4d4d',
    brown: '#a4744a',
    ltBlue2: '#EFF7FF',
    purpleGrey: purple3,
    lightPurple: '#dfdbf2',
  },
  backgrounds: {
    activeGreen: '#417505',
    grey1: '#f5f5f5',
    grey2: '#e0e7f2',
    lightOrange: '#F8DAAB',
    lightRed: '#fd3333',
    lightBlue: '#e0e7f2',
    dkBlue4,
    ltPurpleGrey1,
    default: primaryWhite,
  },
  buttons: {
    primary: {
      default: primaryColor,
      hover: '#228BC7',
      active: '#1A79AF',
      disabled: ltGrey1,
    },
    additional: {
      default: grey3,
      hover: '#747B82',
      active: '#636A70',
      disabled: ltGrey1,
    },
    additionalOutlined: {
      default: primaryWhite,
      hover: ltGrey2,
      active: ltGrey2,
      disabled: primaryWhite,
    },
    link: {
      default: transparent,
      hover: transparent,
      active: transparent,
      disabled: transparent,
    },
    warning: {
      default: orange800,
      hover: brown100,
      active: brown300,
      disabled: ltGrey1,
    },
  },
  notifications: {
    red: {
      900: '#BE000F',
      300: '#EE6C6C',
      100: '#FFCCD0',
      50: '#FFEBED',
    },
    green: {
      900: darkGreen,
      400: '#BBDB57',
      300: '#A8E171',
      100: '#C5E3BF',
      50: '#E7F4E5',
    },
    orange: {
      900: orange900,
      300: '#F3B34C',
      100: '#FADEB1',
      50: '#FDF2E0',
    },
    blue: {
      900: dkBlue1,
      300: '#71A7C0',
      100: '#BBD8E4',
      50: '#E3EFF3',
    },
    purple: {
      900: '#422B7E',
      700: '#22008C',
      300: purple3,
      200: '#E7DCF2',
      100: '#DCC3E6',
      50: '#F1E7F5',
    },
    grey: {
      900: '#303132',
      300: '#9DA2A7',
      100: '#D7D7D7',
      50: '#EFEFEF',
    },
    purpleGrey: {
      900: '#32262F',
      400: '#8365B3',
      300: '#96868E',
      100: '#CECCCD',
      50: ltPurpleGrey1,
    },
  },
  admin: {
    nav: {
      default: '#F2F2F2',
      hover: '#EDEDED',
    },
  },
  input: {
    border: '#DDDDDD',
    borderFocus: '#80BDFF',
    focusColor: '#495057',
  },
  transparent,
};

export default colors;
