export const ACCESS_TOKEN = 'accessToken';

export const REFRESH_TOKEN = 'refreshToken';

export const ONBOARDING_TOKEN = 'onboardingToken';

export const MARKETING_REDIRECT = 'marketingRedirect';

export const AUDIENCE_TOKEN = 'audienceToken';

export const DYNATRACE_USER = 'dynatraceUser';

// this gets set when a user authenticates but can be used by non-authenticated pages
// to display correct units when viewing profiles or reports
export const ACCOUNT_COUNTRY = 'accountCountry';

export const ROLES = 'roles';

export const APPLICATIONS = {
  reps: 'reps',
  casting: 'casting',
  talent: 'talent',
  login: 'login',
  admin: 'admin',
} as const;

export type ApplicationValue = typeof APPLICATIONS[keyof typeof APPLICATIONS];

export const QUERY_PARAMS = {
  redirectAudience: 'redirectAudience',
  redirectUrl: 'redirectUrl',
  divisionId: 'division_id',
  requestType: 'requestType',
  projectIds: 'projectIds',
  slotDateTo: 'slotDateTo',
  slotDateFrom: 'slotDateFrom',
  redirectTo: 'redirectTo',
  listReport: 'listReport',
  scheduleId: 'scheduleId',
  dueDateTo: 'dueDateTo',
  dueDateFrom: 'dueDateFrom',
  couponCode: 'coupon_code',
  defaultAppContext: 'default_app_context',
  savedSearchId: 'savedSearchId',
  theme: 'theme',
};

export const AUDIENCE = 'AUDIENCE';

export const TALENT_URL = 'TALENT_URL';

export const CASTING_URL = 'CASTING_URL';

export const REPS_URL = 'REPS_URL';

export const NODE_ENV = 'NODE_ENV';

export const API_URL = 'API_URL';

export const SCHEDULED_MAINTENANCE = 'b21-scheduled-maintenance-mode';

export const PRODUCTION = 'prd';

export const STAGING = 'stg';

// This variable is used to know if you are running this locally or in a unit test
export const TEST = 'test';

// Used when running the code locally, this can be used to skip third party APIs such
// as heap or braze
export const DEV = 'dev';
