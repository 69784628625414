import { formatPhoneNumberIntl } from 'react-phone-number-input';

const formatPhone = (phone: string, detectedCountryCode: string): string => {
  if (phone) {
    if (typeof phone !== 'string') {
      return '';
    }

    if (!phone?.startsWith?.('+')) {
      return phone;
    }

    if (phone.startsWith('+1') && detectedCountryCode === 'US') {
      const cleanedPhone = phone.replace(/\D/g, '');
      const match = cleanedPhone.match(/(\d{3})(\d{3})(\d{4})$/);

      if (!match) {
        return '';
      }

      return `${match?.[1]}-${match?.[2]}-${match?.[3]}`;
    }

    return formatPhoneNumberIntl(phone);
  }

  return '';
};

export default formatPhone;
