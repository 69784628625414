import { getRouterHistory } from '@minecraft.utils';
import { getEnvironment } from '@minecraft.environment';

const getRedirectQueryParams = (clearStorage, shouldRedirectBack, backUrl): URLSearchParams => {
  const environment = getEnvironment();
  const searchParams = new URLSearchParams();

  if (clearStorage) {
    searchParams.append('clearLocalStorage', 'true');
  }

  if (shouldRedirectBack) {
    const history = getRouterHistory();
    const { location } = history;
    const audience = String(environment['AUDIENCE']).toLowerCase();
    const redirectBackUrl: string = backUrl || location.pathname;

    searchParams.append('redirectAudience', audience);
    searchParams.append('redirectUrl', redirectBackUrl);

    // Set search params, overriding existing is overlaps
    const originalSearchParams = new URLSearchParams(location.search);

    Array.from(originalSearchParams.entries()).forEach(([key, value]) => searchParams.set(key, value));
  }

  return searchParams;
};

export default getRedirectQueryParams;
