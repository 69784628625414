// Error messages based on Error Code numbers from BE
const ErrorMessages: Record<number, string> = {
  1: 'internalServiceError',
  2: 'invalidSessionError',
  3: 'missingSessionTokenError',
  4: 'failedLoginAttemptError',
  5: 'authorizationError',
  6: 'dataNotFoundError',
  7: 'emailAlreadyInUseError',
  8: 'invalidPasswordResetTokenError',
  9: 'accountNotFoundError',
  10: 'validationError',
  11: 'queryError',
  12: 'itemNotCreatedError',
  13: 'invalidOldPasswordError',
  14: 'missingRequestParameterError',
  15: 'itemNotUpdatedError',
  16: 'itemNotDeletedError',
  17: 'itemAlreadyExistsError',
  18: 'invalidEmailResetTokenError',
  19: 'accountSystemRolesNotFound',
  20: 'unprocessableEntity',
  21: 'userWithWrongStatus',
  22: 'accountNotFoundOnInstance',
  23: 'invalidToken',
  24: 'emailTokenExpired',
  25: 'accountClaim',
  26: 'authTokenNoLongerValid',
  27: 'accountLocked',
  28: 'multipleInstances',
  29: 'accountOrgClosed',
  504: 'internalError',
  1001: 'authUserWithDiffStatus',
  1002: 'authInvalidLinkToken',
  1003: 'emailExists',
  1004: 'noAccountFoundInstance',
  1005: 'authUserNotFound',
  1006: 'authInvalidToken',
  1007: 'authEmailTokenExp',
  1008: 'authAccountClaim',
  1009: 'authAccountActive',
  1010: 'authAccountVerifyWithDiffStatus',
  23505: 'dbErrorUnique',
};

export default ErrorMessages;
