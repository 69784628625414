import React from 'react';
import styled from 'styled-components';
import { H4Text } from './Typography';
import { FlexRow } from './Containers';

export interface DividerProps {
  label?: string;
  className?: string;
  hideDivider?: boolean;
}

const DividerLine = styled.hr`
  flex-grow: 1;
  border: none;
  border-top: ${(props) => `1px solid ${props.theme.colors.primary.darkGrey}`};
  margin: 0;
`;

export const DividerWithLabel: React.FC<DividerProps> = ({ label = 'OR', className = '', hideDivider = false }) => {
  if (hideDivider) return null;

  return (
    <FlexRow alignItems="center" className="cn_w-full">
      <DividerLine />
      <H4Text as="span" fontWeight="regular" className={`cn_atom_p-4 ${className}`}>
        {label}
      </H4Text>
      <DividerLine />
    </FlexRow>
  );
};
