import { TranslateFn } from '@blocs.i18n';
import { gqlProjectStatus, gqlProjectStatusEnum as PROJECT_STATUS } from '@minecraft.graphql-types';
import { StatusColor } from '@minecraft.themes';

type ReturnType = {
  displayName: string;
  color: (props: any) => string;
  colorStatus: StatusColor;
};

export const getProjectStatusInfo = (
  hasExclusiveStatus: boolean,
  projectStatus: Pick<gqlProjectStatus, 'code' | 'name'>,
  t: TranslateFn
): ReturnType => {
  if (!projectStatus) {
    return {
      displayName: t('common:globalError.generic'),
      color: (props): string => props.theme.designSystem.textColors.statusDefault,
      colorStatus: 'statusRejected',
    };
  }

  if (hasExclusiveStatus) {
    switch (projectStatus.code) {
      case PROJECT_STATUS.DRAFT:
      case PROJECT_STATUS.NOT_YET_PUBLISHED:
        return {
          displayName: t('common:label.draft'),
          color: (props): string => props.theme.designSystem.textColors.statusDefault,
          colorStatus: 'statusDefault',
        };
      case PROJECT_STATUS.NEW_PROJECT_PENDING:
      case PROJECT_STATUS.MODIFICATIONS_COMPLETE:
        return {
          displayName: t('common:label.underReview'),
          color: (props): string => props.theme.designSystem.textColors.statusUnderReview,
          colorStatus: 'statusUnderReview',
        };
      case PROJECT_STATUS.MODIFICATIONS_REQUESTED:
        return {
          displayName: t('common:label.changesRequested'),
          color: (props): string => props.theme.designSystem.textColors.statusChangesRequested,
          colorStatus: 'statusChangesRequested',
        };
      case PROJECT_STATUS.ACTIVE:
        return {
          displayName: t('common:label.active'),
          color: (props): string => props.theme.designSystem.textColors.statusActive,
          colorStatus: 'statusActive',
        };
      case PROJECT_STATUS.REJECTED:
        return {
          displayName: t('common:label.rejected'),
          color: (props): string => props.theme.designSystem.textColors.statusRejected,
          colorStatus: 'statusRejected',
        };
      case PROJECT_STATUS.EDIT_TO_ACTIVE_PROJECT:
        return {
          displayName: t('common:label.changesUnderReview'),
          color: (props): string => props.theme.designSystem.textColors.statusUnderReview,
          colorStatus: 'statusUnderReview',
        };
      case PROJECT_STATUS.SUBMISSIONS_CLOSED:
        return {
          displayName: t('common:label.submissionsClosed'),
          color: (props): string => props.theme.designSystem.textColors.statusClosed,
          colorStatus: 'statusClosed',
        };
      case PROJECT_STATUS.ARCHIVED:
        return {
          displayName: t('common:label.archived'),
          color: (props): string => props.theme.designSystem.textColors.statusDefault,
          colorStatus: 'statusDefault',
        };
      case PROJECT_STATUS.ARCHIVE_IN_PROGRESS:
        return {
          displayName: t('common:label.archiveInProgress'),
          color: (props): string => props.theme.designSystem.textColors.statusDefault,
          colorStatus: 'statusDefault',
        };
      case PROJECT_STATUS.RESTORE_IN_PROGRESS:
        return {
          displayName: t('common:label.restoreInProgress'),
          color: (props): string => props.theme.designSystem.textColors.statusDefault,
          colorStatus: 'statusDefault',
        };
      default:
        return {
          displayName: projectStatus.name,
          color: (props): string => props.theme.designSystem.textColors.statusDefault,
          colorStatus: 'statusDefault',
        };
    }
  }

  switch (projectStatus.code) {
    case PROJECT_STATUS.NOT_YET_PUBLISHED:
      return {
        displayName: t('common:label.notYetPublished'),
        color: (props): string => props.theme.designSystem.textColors.statusDefault,
        colorStatus: 'statusDefault',
      };
    case PROJECT_STATUS.ACTIVE:
      return {
        displayName: t('common:label.active'),
        color: (props): string => props.theme.designSystem.textColors.statusActive,
        colorStatus: 'statusActive',
      };
    case PROJECT_STATUS.SUBMISSIONS_CLOSED:
      return {
        displayName: t('common:label.submissionsClosed'),
        color: (props): string => props.theme.designSystem.textColors.statusClosed,
        colorStatus: 'statusClosed',
      };
    case PROJECT_STATUS.ARCHIVED:
      return {
        displayName: t('common:label.archived'),
        color: (props): string => props.theme.designSystem.textColors.statusDefault,
        colorStatus: 'statusDefault',
      };
    case PROJECT_STATUS.ARCHIVE_IN_PROGRESS:
      return {
        displayName: t('common:label.archiveInProgress'),
        color: (props): string => props.theme.designSystem.textColors.statusDefault,
        colorStatus: 'statusDefault',
      };
    case PROJECT_STATUS.RESTORE_IN_PROGRESS:
      return {
        displayName: t('common:label.restoreInProgress'),
        color: (props): string => props.theme.designSystem.textColors.statusDefault,
        colorStatus: 'statusDefault',
      };
    default:
      return {
        displayName: projectStatus.name,
        color: (props): string => props.theme.designSystem.textColors.statusDefault,
        colorStatus: 'statusDefault',
      };
  }
};
