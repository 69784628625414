import React from 'react';
import classNames from 'classnames';
import styled, { css, useTheme } from 'styled-components';
import { Theme } from '@minecraft.themes';
import Icon from '@minecraft.icon';
import { BUTTON_COLOR, ButtonProps, ChildElementConfig, ElementAlignment, VARIANTS, Variants } from './types';

interface StyledButtonProps {
  $isTall?: boolean;
  $variant?: Variants;
  $isSmall?: boolean;
  $color: string;
  $fixedWidth: boolean;
  theme: Theme;
  'aria-label'?: string;
  $iconOnly?: boolean;
}

type InnerGridProps = {
  groupLabelWithStartIcon: boolean;
  columnConfig: {
    showStartIcon: boolean;
    showEndIcon: boolean;
    showLabel: boolean;
  };
};

type GetDefaultedResponsiveValue = {
  /**
   * Default for 'sm' size
   */
  defaultSm?: boolean;
  /**
   * Default for 'md' size
   */
  defaultMd?: boolean;
  /**
   * Default for 'lg' size
   */
  defaultLg?: boolean;
  /**
   * Any value you'd like to use for 'sm'
   * if left unset, it'll use the default provided
   */
  sm?: boolean;
  /**
   * Any value you'd like to use for 'md'
   * if left unset it'll use the set 'sm' value
   * if 'sm' is also unset, it'll use the default provided
   */
  md?: boolean;
  /**
   * Any value you'd like to use for 'lg'
   * if left unset it'll use the set 'md' value
   * if 'md' is also unset, it'll use the set 'sm' value
   * if 'sm' is also unset, it'll use the default provided (cascade)
   */
  lg?: boolean;
  /**
   * The size you want to get the value for
   * this is used to determine the cascade pattern and where to stop
   * Example: if you ask for 'md' it will ignore any values set in the 'lg' param
   */
  forSize?: 'sm' | 'md' | 'lg';
};

const smDefaults = { full: true, block: true };
const mdDefaults = { full: false, block: false };
const lgDefaults = { full: false, block: false };

/**
 * This applies the cascade props and takes into account any defaults you
 * may have provided
 */
export const getDefaultedResponsiveValue = ({
  defaultSm,
  defaultMd,
  defaultLg,
  sm,
  md,
  lg,
  forSize,
}: GetDefaultedResponsiveValue) => {
  let resultSm = defaultSm;
  let resultMd = defaultMd;
  let resultLg = defaultLg;

  if (sm != null) {
    resultSm = sm;
  }

  if (md != null) {
    resultMd = md;
  } else if (sm != null) {
    resultMd = sm;
  }

  if (lg != null) {
    resultLg = lg;
  } else if (md != null) {
    resultLg = md;
  } else if (sm != null) {
    resultLg = sm;
  }

  if (forSize === 'lg') {
    return resultLg ?? resultMd ?? resultSm;
  }

  if (forSize === 'md') {
    return resultMd ?? resultSm;
  }

  return resultSm;
};

/**
 * Applies the default values for the given size so we don't have to
 * repeat ourselves when writing them in the component
 */
const getDefaultedResponsiveAttributesWithDefaults = ({
  block,
  full,
  mdProps,
  lgProps,
  forSize,
}: Pick<ButtonProps, 'block' | 'full' | 'mdProps' | 'lgProps'> & Pick<GetDefaultedResponsiveValue, 'forSize'>): {
  block: boolean;
  full: boolean;
} => {
  return {
    block: getDefaultedResponsiveValue({
      defaultSm: smDefaults.block,
      defaultMd: mdDefaults.block,
      defaultLg: lgDefaults.block,
      sm: block,
      md: mdProps?.block,
      lg: lgProps?.block,
      forSize,
    }),
    full: getDefaultedResponsiveValue({
      defaultSm: smDefaults.full,
      defaultMd: mdDefaults.full,
      defaultLg: lgDefaults.full,
      sm: full,
      md: mdProps?.full,
      lg: lgProps?.full,
      forSize,
    }),
  };
};

const getMinWidth = ({
  $fixedWidth,
  $isSmall,
  theme,
}: Pick<StyledButtonProps, '$fixedWidth' | '$isSmall' | 'theme'>) => {
  if (!$fixedWidth) {
    return '1rem';
  }

  return $isSmall ? theme.button.size.small.minWidth : theme.button.size.large.minWidth;
};

/**
 * @internal
 */
export const StyledButton = styled.button<StyledButtonProps>`
  border-radius: ${({ theme }): string => theme.button.borderRadius};
  min-width: ${({ theme, $isSmall, $fixedWidth }) => getMinWidth({ theme, $isSmall, $fixedWidth })};

  && {
    color: ${({ theme, $color, $variant }): string =>
      theme.button.color[$color].outlined[String($variant === VARIANTS.secondary)].state.default.color};
  }
  background-color: ${({ theme, $color, $variant }): string =>
    theme.button.color[$color].outlined[String($variant === VARIANTS.secondary)].state.default.backgroundColor};
  border: ${({ theme, $color, $variant }): string =>
    theme.button.color[$color].outlined[String($variant === VARIANTS.secondary)].state.default.border};

  text-decoration: ${({ theme, $color, $variant }) =>
    theme.button.color[$color].outlined[String($variant === VARIANTS.secondary)].state.default.textDecoration ??
    'none'};

  font-weight: ${({ theme, $isSmall }): string => theme.button.size[$isSmall ? 'small' : 'large'].fontWeight};
  font-size: ${({ theme, $isSmall }): string => theme.button.size[$isSmall ? 'small' : 'large'].fontSize};

  &:hover {
    color: ${({ theme, $color, $variant }): string =>
      theme.button.color[$color].outlined[String($variant === VARIANTS.secondary)].state.hover.color};
    background-color: ${({ theme, $color, $variant }): string =>
      theme.button.color[$color].outlined[String($variant === VARIANTS.secondary)].state.hover.backgroundColor};
    border: ${({ theme, $color, $variant }): string =>
      theme.button.color[$color].outlined[String($variant === VARIANTS.secondary)].state.hover.border};
    text-decoration: ${({ theme, $color, $variant }) =>
      theme.button.color[$color].outlined[String($variant === VARIANTS.secondary)].state.hover.textDecoration ??
      'none'};
  }

  &:focus {
    color: ${({ theme, $color, $variant }): string =>
      theme.button.color[$color].outlined[String($variant === VARIANTS.secondary)].state.focus.color};
    background-color: ${({ theme, $color, $variant }): string =>
      theme.button.color[$color].outlined[String($variant === VARIANTS.secondary)].state.focus.backgroundColor};
    border: ${({ theme, $color, $variant }): string =>
      theme.button.color[$color].outlined[String($variant === VARIANTS.secondary)].state.focus.border};
    box-shadow: ${({ theme, $color, $variant }): string =>
      theme.button.color[$color].outlined[String($variant === VARIANTS.secondary)].state.focus.boxShadow ?? 'none'};
  }

  &:active,
  &:not(:disabled):not(.disabled):active {
    color: ${({ theme, $color, $variant }): string =>
      theme.button.color[$color].outlined[String($variant === VARIANTS.secondary)].state.active.color};
    background-color: ${({ theme, $color, $variant }): string =>
      theme.button.color[$color].outlined[String($variant === VARIANTS.secondary)].state.active.backgroundColor};
    border: ${({ theme, $color, $variant }): string =>
      theme.button.color[$color].outlined[String($variant === VARIANTS.secondary)].state.active.border};
    text-decoration: ${({ theme, $color, $variant }): string =>
      theme.button.color[$color].outlined[String($variant === VARIANTS.secondary)].state.active.textDecoration ??
      'none'};
  }

  &:disabled {
    color: ${({ theme, $color, $variant }): string =>
      theme.button.color[$color].outlined[String($variant === VARIANTS.secondary)].state.disabled.color};
    background-color: ${({ theme, $color, $variant }): string =>
      theme.button.color[$color].outlined[String($variant === VARIANTS.secondary)].state.disabled.backgroundColor};
    border: ${({ theme, $color, $variant }): string =>
      theme.button.color[$color].outlined[String($variant === VARIANTS.secondary)].state.disabled.border};
    text-decoration: ${({ theme, $color, $variant }): string =>
      theme.button.color[$color].outlined[String($variant === VARIANTS.secondary)].state.disabled.textDecoration ??
      'none'};
    cursor: not-allowed;
  }

  * + * {
    margin-left: 0.5rem;
  }

  ${({ $iconOnly }) =>
    $iconOnly &&
    css`
      padding: 0;
      border: none;
      background: none;
    `}
`;

const InnerGrid = styled.div<InnerGridProps>`
  display: ${({ groupLabelWithStartIcon, columnConfig }) =>
    groupLabelWithStartIcon && !columnConfig.showEndIcon ? 'flex' : 'grid'};
  grid-template-columns: ${({ groupLabelWithStartIcon, columnConfig }) => {
    if (groupLabelWithStartIcon) return '1fr 1fr auto';

    const { showEndIcon, showStartIcon, showLabel } = columnConfig;

    if (!showStartIcon && showEndIcon && showLabel) return '1fr auto';

    if (!showStartIcon && !showEndIcon && showLabel) return '1fr';

    if (showStartIcon && showLabel) return 'auto 1fr auto';

    return '1fr';
  }};
  place-items: center;
  align-content: center;
  justify-content: center;
`;

// There's an odd visual quirk where the icon when put into the first slot
// will make the entire button lift higher than any others.  Reducing the
// size of the icon fixes the issue but isn't a viable option.  So we need
// to simply adjust where it renders and take it out of the "box" to do that.
const QuirkIconContainer = styled.div`
  position: relative;

  svg {
    margin-top: -0.25rem;
  }
`;

const StyledIcon = styled(Icon)<{ justify: ElementAlignment }>`
  justify-self: ${({ justify }) => justify};
  fill: currentColor;
`;

const StyledLabel = styled.span<{ justify: ElementAlignment }>`
  justify-self: ${({ justify }) => justify};
`;

/**
 * The Button component.  This component should never be used directly as you should be using
 * a "named" component that extends this. WarningButton is an example.
 * [Figma](https://www.figma.com/file/AiDAD1WpprgfbZI2XV6hU1/Casting-Networks-Design-System?node-id=209-1587&t=bGGx2DvyYFpOohgl-0)
 *
 * Note that mdProps set the props for the button when the screen is between 769px and 1200px (per designs and CSS).
 * lgProps set the props for the button when the screen is greater than 1200px (per designs and CSS).
 *
 * The button comes with some defaults that are typically used within Casting Networks.  This means
 * that on mobile it will be displayed as block and full width, while other sizes will
 * default to inline and auto width.  If you need to adjust these, use the mdProps and lgProps
 * but you should bring it up to design and product as it's not the typical configuration.
 *
 * @example
 * <Button color="primary" variant="secondary" label="Click Me" mdProps={{ block: true }} />
 */
export const Button: React.FC<ButtonProps> = ({
  variant = 'solid',
  isSmall = false,
  iconOnly = false,
  color = 'primary',
  className,
  fixedWidth = false,
  mdProps,
  lgProps,
  block,
  full,
  label,
  'aria-label': ariaLabel,
  startIcon,
  endIcon,
  wordWrap,
  ...rest
}) => {
  const { button } = useTheme() as Theme;

  const buttonFontSize = button.size[isSmall ? 'small' : 'large'].fontSize;
  // Used when it's an icon-only button, the icon needs to grow larger than what it would be next to text
  const iconOnlySize = isSmall ? '0.88' : '1.38';

  const showStartIcon = !!startIcon;
  const showEndIcon = !!endIcon;
  const showLabel = !!label;

  const columnConfig = { showStartIcon, showLabel, showEndIcon };

  const startIconConfig: ChildElementConfig =
    typeof startIcon === 'string' ? { name: startIcon, justify: 'start' } : startIcon;
  const endIconConfig: ChildElementConfig = typeof endIcon === 'string' ? { name: endIcon, justify: 'end' } : endIcon;
  const labelConfig: ChildElementConfig = typeof label === 'string' ? { name: label, justify: 'center' } : label;
  const labelString = typeof label === 'string' ? label : label?.name;

  const groupLabelWithStartIcon =
    showStartIcon && showLabel && labelConfig?.justify === 'start' && fixedWidth && startIconConfig?.justify === 'end';

  // This is what 'cascade' looks like.
  // When you set "block" in base props (mobile) it'll apply that setting to all sizes larger unless you override it.
  const smResult = getDefaultedResponsiveAttributesWithDefaults({
    block,
    full,
    mdProps,
    lgProps,
    forSize: 'sm',
  });

  const mdResult = getDefaultedResponsiveAttributesWithDefaults({
    block,
    full,
    mdProps,
    lgProps,
    forSize: 'md',
  });

  const lgResult = getDefaultedResponsiveAttributesWithDefaults({
    block,
    full,
    mdProps,
    lgProps,
    forSize: 'lg',
  });

  return (
    <StyledButton
      $iconOnly={iconOnly}
      $fixedWidth={fixedWidth}
      $isSmall={isSmall}
      $color={BUTTON_COLOR[color] || 'primary'}
      $variant={variant}
      aria-label={ariaLabel || labelString}
      className={classNames(
        wordWrap === 'wrap' ? 'cn_wrap-text' : 'cn_no-wrap-text',
        iconOnly ? 'cn_atom_px-0' : 'cn_atom_px-4',
        isSmall ? 'cn_atom_py-1' : 'cn_atom_py-2',
        smResult.block ? `cn_block` : `cn_inline-block`,
        mdResult.block ? `cn_md:block` : `cn_md:inline-block`,
        lgResult.block ? `cn_lg:block` : `cn_lg:inline-block`,
        smResult.full ? 'cn_w-full' : 'cn_w-auto',
        mdResult.full ? `cn_md:w-full` : `cn_md:w-auto`,
        lgResult.full ? `cn_lg:w-full` : `cn_lg:w-auto`,
        className
      )}
      {...rest}
      // these data-attributes can be used in test assertions
      // so that you don't need to assert against the styles
      // data-btn-color is the value of the color prop
      data-btn-color={color || 'primary'}
      // data-btn-variant indicates if this is the "secondary" or "solid" version of the button
      data-btn-variant={variant}
    >
      {iconOnly ? (
        <StyledIcon
          inheritColor
          name={startIconConfig?.name ?? endIconConfig?.name}
          height={iconOnly ? iconOnlySize : buttonFontSize}
          width={iconOnly ? iconOnlySize : buttonFontSize}
          justify={startIconConfig?.justify ?? endIconConfig?.justify}
        />
      ) : (
        <InnerGrid columnConfig={columnConfig} groupLabelWithStartIcon={groupLabelWithStartIcon}>
          {showStartIcon && (
            <QuirkIconContainer>
              <StyledIcon
                inheritColor
                name={startIconConfig?.name}
                height={iconOnly ? iconOnlySize : buttonFontSize}
                width={iconOnly ? iconOnlySize : buttonFontSize}
                justify={startIconConfig?.justify}
              />
            </QuirkIconContainer>
          )}
          {showLabel && <StyledLabel justify={labelConfig?.justify}>{labelConfig?.name}</StyledLabel>}
          {showEndIcon && (
            <StyledIcon
              inheritColor
              name={endIconConfig?.name}
              height={iconOnly ? iconOnlySize : buttonFontSize}
              width={iconOnly ? iconOnlySize : buttonFontSize}
              justify={endIconConfig?.justify}
            />
          )}
        </InnerGrid>
      )}
    </StyledButton>
  );
};
